.body-container {
  /* border: solid red 1px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  row-gap: 10px;
  column-gap: 20px;
  width: 100%;
}

.item-container {
  z-index: 1;
  border: solid lightgray 1px;
  display: flex;
  height: 200px;
  width: 400px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  /* margin: 0 auto; */
  /* position: absolute; */
  /* top: 50%; */
  /* transform: translate(0, -50%); */
}

.item-content {
  display: block;
  position: relative;
  /* padding-top: 50%; */
  /* padding-bottom: auto; */
  /* text-align: center; */
  /* padding: 50%; */
}

.page-content {
  padding: 16px;
}