div .contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contact-card-header-icon {
  padding: 10px;
  font-weight: bold;
  font-size: medium;
}

.contact-card-header {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: medium;
  text-align: left;
}

.contact-card-text {
  font-size: medium;
  display: block;
  text-align: left;
  font-variant: normal;
  font-style: normal;
  padding-right: 10px;
}


.contact-card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  font-weight: normal;
  font-size: small;
}

.icon-size {
  font-size: 4rem;
}