/* Button styles*/

.buttonStyleDefault {
  width: 20px;
  min-width: 0px;
}

.toggleButtonStyle {
  width: 150px;
  border-radius: 7px;
  margin-right: 5px;
  margin-bottom: 5px;
}

div .calendar-component-container {
  /* border: solid red 1px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  row-gap: 10px;
  column-gap: 20px;
  width: 100%;
}

div .calendar-component-cvr {
  /* border: solid red 1px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  row-gap: 5px;
  column-gap: 5px;
  width: 100%;
}

div .calendar-component-fragment {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 0px;
  column-gap: 5px;
  border: none;
  width: auto;
}

div .calendar-month-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-day-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-time-slot-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-day-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-timeSlot-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-timeSlot-confirmation-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-topic-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-comment-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

div .calendar-fragment-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 0px;
  column-gap: 5px;
  border: none;
  width: auto;
}

.logo {
  width: 200px;
}

.text-xlarge {
  font-size: 2.8rem;
  line-height: 4rem;
  font-weight: 600;
  color: #1a1a1a;
  text-transform: none;
  max-width: 66ch;
}

.text-large {
  font-size: 1.5rem;
  line-height: 4rem;
  font-weight: 400;
  color: #1a1a1a;
  text-transform: none;
  max-width: 66ch;
}

.text-normal {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 300;
  color: #1a1a1a;
  text-transform: none;
  max-width: 66ch;
}

div h4 {
  display: contents;
  font-weight: normal;
  font-size: smaller;
}

/* TextInputPicker */
.calendar-text-input-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}

/* EmailInputPicker */
.calendar-email-input-picker-component-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  row-gap: 5px;
  column-gap: 5px;
  border: none;
  width: 100%;
}