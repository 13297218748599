#navbar {
  width: 100%;
  overflow: hidden;
  z-index: 999;
}

nav ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background-color: #333;
}

nav ul li:first-child {
  float: left;
  border: none;
}

nav ul li {
  float: left;
  border-left: 1px solid white;
}

nav ul li:last-child {
  float: left;
  border-right: 1px solid white;
}

nav ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
nav ul li a:hover {
  text-decoration-line: underline;
  text-decoration-color: #0078d4;
}

nav ul li .active {
  background-color: #0078d4;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky+.content {
  padding-top: 60px;
}

div .menu-header {
  background-color: #f1f1f1;
  padding: 40px;
  overflow: hidden;
  text-align: center;
}

div .menu-header span {
  max-height: 80px;
  overflow: hidden;
}

.block {
  display: block;
}